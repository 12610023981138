.custom-message-dialog {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;

  z-index: 2;
  pointer-events: none;

  padding-bottom: 2em;
}

.custom-message-container {
  background:  rgba(23,27,38, .87);
  height: 100%;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 2em 0.2em;
}

.custom-message-cancel {
  align-self: flex-end;
  user-select: none;
  pointer-events: auto;
  cursor: pointer;
  z-index: 1;

  fill: white;
  background: none;
  border: none;
  margin: 0;
  margin-top: 1em;
  font-weight: bold;
  font-size: 1em;
}

/* Custom message input section */

.custom-message-form {
  position: relative;
  height: 18em;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;

  border-radius: 4px;
  padding: 1em;
}

@media screen and (min-width: 800px) {
  .custom-message-dialog {
    justify-content: center;
  }
  .custom-message-container {
    width: 50%;
    height: 60%;
  }
}

.custom-message__input-container {
  position: relative;
}

.character-counter {
  position: absolute;
  bottom: 0;
  left: 90%;
  margin-bottom: 1.5em;

  font-family: "Effra Medium";
  font-size: 12px;
  color: white;
}

.custom-message__input,
.signature__input,
.signature {
  background: none;
  font-family: 'nickainleynormal';
  text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.8);
  color: white;
}

.custom-message__input,
.signature__input {
  border: none;
  border-bottom: 2px solid white;
  pointer-events: auto;
}

.custom-message__input {
  margin-bottom: 1em;
  overflow: hidden;
  resize: none;
  width: 100%;

  font-size: 36px;
  text-align: center;
}

.signature {
  font-size: 24px;
  margin-right: .8em;
}

.signature__input {
  height: 1em;
  width: 8em;
  font-size: 24px;
  font-weight: bold;
  padding-left: .1em;
}

/* Share on social media section */

.share-container {
  border-radius: 4px;
  padding: 1em;
  position: relative;
}

.share-hint {
  margin: 0;
  font-family: 'Effra Medium';
  font-size: 18px;
  color: white;
}

.share-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  pointer-events: auto;
}

.share-button {
  width: 48px;
  height: 48px;

  border-radius: 2px;
  border: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  margin-left: 1.2em;
  padding: 0;
  background: white;
  fill: #171b26;
  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: center;

  line-height: 0px;
  font-size: 18px;
  cursor: pointer;
}
.share-button:first-child {
  margin-left: 0;
}
.share-button:hover {
  background: grey;
}
